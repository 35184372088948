import { PropsWithChildren, ReactElement } from 'react';
import { IconButton, Stack, SxProps, Theme, Typography } from '@mui/material';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { FaIcon } from '../../DataDisplay/Icon/FaIcon';

export type SrFiltersPanelProps = {
  sx?: SxProps<Theme>;
};

export const SrFiltersPanel = ({
  children,
  sx,
}: PropsWithChildren<SrFiltersPanelProps>): ReactElement => {
  return (
    <Stack
      sx={[
        {
          borderRadius: 0.5,
          backgroundColor: 'background.secondary',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Stack>
  );
};

export type SrFiltersPanelHeaderProps = {
  sx?: SxProps<Theme>;
  onClose?(): void;
};

const Header = ({
  sx,
  onClose,
  children,
}: PropsWithChildren<SrFiltersPanelHeaderProps>) => {
  return (
    <Typography
      variant="h2"
      component={Stack}
      sx={[
        {
          color: 'text.primary',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 1,
          py: 1,
          px: 1.5,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
      {onClose && (
        <IconButton onClick={onClose}>
          <FaIcon icon={faXmark} fontSize="medium" />
        </IconButton>
      )}
    </Typography>
  );
};

export type SrFiltersPanelContentProps = {
  sx?: SxProps<Theme>;
};

const Content = ({
  sx,
  children,
}: PropsWithChildren<SrFiltersPanelContentProps>) => {
  return (
    <Stack
      sx={[
        { gap: 1, px: 1, pt: 1, pb: 2, flex: 1, overflowY: 'auto' },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Stack>
  );
};

export type SrFiltersPanelFooterProps = {
  sx?: SxProps<Theme>;
};

const Footer = ({
  sx,
  children,
}: PropsWithChildren<SrFiltersPanelFooterProps>) => {
  return (
    <Stack
      gap={1}
      direction="row"
      sx={[
        {
          p: 2,
          justifyContent: 'end',
          borderTop: '3px solid',
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
          borderTopColor: 'divider',
          backgroundColor: 'background.secondary',
          button: { flexGrow: 1 },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Stack>
  );
};

SrFiltersPanel.Header = Header;
SrFiltersPanel.Content = Content;
SrFiltersPanel.Footer = Footer;
